export const HomePageExplore = [
    {
        tag: 'Free',
        courses : [
            {
                heading : "Learn HTML",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Learn CSS",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Responsive Web design",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessonNumber : 6
            },
        ]
    },
    {
        tag: 'New to Coding',
        courses : [
            {
                heading : "HTML",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "CSS",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Responsive ",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessonNumber : 6
            },
        ]
    },
    {
        tag: 'Most Popular',
        courses : [
            {
                heading : "Java",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Python",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "SCSS",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessonNumber : 6
            },
        ]
    },
    {
        tag: 'Skills Paths',
        courses : [
            {
                heading : "Flask",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Django",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Fast API",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessonNumber : 6
            },
        ]
    },
    {
        tag: 'Career Paths',
        courses : [
            {
                heading : "Next.js",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Nuxt.js",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessonNumber : 6
            },
            {
                heading : "Sanity",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessonNumber : 6
            },
        ]
    },
]