import React from "react";
import HighlightTextBlue from "../HomePage/HighlightTextBlue";
import CTAbutton from "../HomePage/CTAbutton";

const LearningGridArray = [
  {
    order: -1,
    heading: "World-Class Learning for",
    highlightText: "Anyone, Anywhere",
    description:
      "Studynotion partners with more than 275+ leading universities and companies to bring flexible, affordable, job-relevant online learning to individuals and organizations worldwide.",
    BtnText: "Learn More",
    BtnLink: "/",
  },
  {
    order: 1,
    heading: "Curriculum Based on Industry Needs",
    description:
      "Save time and money! The Belajar curriculum is made to be easier to understand and in line with industry needs.",
  },
  {
    order: 2,
    heading: "Our Learning Methods",
    description:
      "Studynotion partners with more than 275+ leading universities and companies to bring",
  },
  {
    order: 3,
    heading: "Certification",
    description:
      "Studynotion partners with more than 275+ leading universities and companies to bring",
  },
  {
    order: 4,
    heading: `Rating "Auto-grading"`,
    description:
      "Studynotion partners with more than 275+ leading universities and companies to bring",
  },
  {
    order: 5,
    heading: "Ready to Work",
    description:
      "Studynotion partners with more than 275+ leading universities and companies to bring",
  },
];

const LearningGrid = () => {
  return (
    <div className="grid mx-auto w-[350px] xl:w-fit grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-12">
      {
        LearningGridArray.map((card, i) => {
            return (
            <div
                key={i}
                className={`${i === 0 && "xl:col-span-2 xl:h-[294px]"}  ${
                card.order % 2 === 1
                    ? "bg-richblack-700 h-[294px]"
                    : card.order % 2 === 0
                    ? "bg-richblack-800 h-[294px]"
                    : "bg-transparent"
                } ${card.order === 3 && "xl:col-start-2"}  `}
                //i will run from 0 to 5, and not from -1 (that is card.order)
                

            >
                {card.order < 0 ? (
                <div className="xl:w-[90%] flex flex-col gap-3 pb-10 xl:pb-0">
                    <div className="text-4xl font-semibold ">
                    {card.heading}
                    <HighlightTextBlue text={card.highlightText} />
                    </div>
                    <p className="text-richblack-300 font-medium">
                    {card.description}
                    </p>

                    <div className="w-fit mt-2">
                    <CTAbutton active={true} linkto={card.BtnLink}>
                        {card.BtnText}
                    </CTAbutton>
                    </div>
                </div>
                ) : (
                <div className="p-8 flex flex-col gap-8">
                    <h1 className="text-richblack-5 text-xl">{card.heading}</h1>

                    <p className="text-richblack-300 font-medium">
                    {card.description}
                    </p>
                </div>
                )}
            </div>
            );
        }
      )}
    </div>
  );
};

export default LearningGrid;
